@keyframes mvFadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes mvFadeinText {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.page-top .top-mv__main {
  position: relative;
  width: 100%;
}
@media (max-width: 767px) {
  .page-top .top-mv__main {
    height: 600rem;
    padding-top: var(--heightHeaderSp);
    background: url("/assets/img/top/main_bg_sp.jpg") #E76436 no-repeat center bottom/100% auto;
  }
}
@media (min-width: 768px) {
  .page-top .top-mv__main {
    background: url("/assets/img/top/main_bg.jpg") #E76436 no-repeat bottom 0% right 28%/1500rem auto;
    padding: calc(var(--heightHeaderPc) + 150rem) var(--sidePaddingPc1) 0;
    height: 960rem;
  }
}
@media (max-width: 767px) {
  .page-top .top-mv__message-container {
    margin: 0 auto;
    width: 340rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-mv__message-container {
    max-width: 1070rem;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .page-top .top-mv__message {
    max-width: 496rem;
  }
}
.page-top .top-mv__message__content {
  display: block;
  font-size: 0;
  margin-left: -25rem;
}
.page-top .top-mv__message__content,
.page-top .top-mv__message__text {
  opacity: 0;
}
.page-top .top-mv__message-container.is-active .top-mv__message__content {
  animation: mvFadein 1.3s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}
.page-top .top-mv__message-container.is-active .top-mv__message__text {
  animation: mvFadeinText 1.3s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}
@media (max-width: 767px) {
  .page-top .top-mv__logo-text {
    display: none;
  }
}
@media (min-width: 768px) {
  .page-top .top-mv__logo-text {
    position: absolute;
    bottom: 40rem;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1388rem;
    width: calc(100% - var(--sidePaddingPc1) * 2);
  }
}
.page-top .top-mv__button {
  width: 160rem;
  margin-top: 30rem;
  opacity: 0;
  animation: mvFadein 1.3s 5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}
@media (min-width: 768px) {
  .page-top .top-mv__button {
    width: 300rem;
    margin-top: 60rem;
  }
}
.page-top .top-mv__message-container.is-active .top-mv__button {
  animation: mvFadein 1.3s 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}
.page-top .top-feature {
  display: flex;
  justify-content: center;
  margin-top: 100rem;
}
@media (min-width: 768px) {
  .page-top .top-feature {
    margin-top: 160rem;
  }
}
.page-top .top-feature__inner {
  text-align: center;
  margin: 0 var(--sidePaddingSp1);
}
@media (min-width: 768px) {
  .page-top .top-feature__inner {
    width: calc(100% - var(--sidePaddingPc1) * 2);
    max-width: var(--widthDefault);
    margin: 0 auto;
  }
}
.page-top .top-feature__heading .m-section-heading__main {
  color: var(--colorMain1);
  font-size: 32rem;
}
@media (min-width: 768px) {
  .page-top .top-feature__heading .m-section-heading__main {
    font-size: 48rem;
  }
}
.page-top .top-feature__sub-heading {
  font-size: 28rem;
  font-weight: 600;
  line-height: 1.785;
  color: var(--colorMain1);
  margin-top: 30rem;
}
@media (min-width: 768px) {
  .page-top .top-feature__sub-heading {
    font-size: 42rem;
    margin-top: 50rem;
  }
}
.page-top .top-feature__sub-heading__item {
  display: block;
}
.page-top .top-feature__sub-heading__item:not(:last-child) {
  margin-bottom: 15rem;
}
@media (min-width: 768px) {
  .page-top .top-feature__sub-heading__item:not(:last-child) {
    margin-bottom: 20rem;
  }
}
.page-top .top-feature__sub-heading__line {
  background: linear-gradient(transparent 60%, var(--colorMain3) 0%);
}
.page-top .top-feature__lead {
  font-weight: 700;
  font-size: 28rem;
  margin-top: 28rem;
  letter-spacing: 0.08em;
}
@media (min-width: 768px) {
  .page-top .top-feature__lead {
    font-size: 48rem;
  }
}
.page-top .top-feature__list {
  margin-top: 50rem;
}
@media (min-width: 768px) {
  .page-top .top-feature__list {
    display: flex;
    justify-content: space-between;
    margin-top: 120rem;
  }
}
@media (max-width: 767px) {
  .page-top .top-feature__list__item {
    width: 292rem;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .page-top .top-feature__list__item {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .page-top .top-feature__list__item:not(:last-child) {
    border-bottom: 2px dotted #C1BCBC;
    padding-bottom: 34rem;
    margin-bottom: 48rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-feature__list__item:not(:last-child) {
    border-right: 2px dotted #C1BCBC;
  }
}
@media (min-width: 768px) {
  .page-top .top-feature__list__item:nth-child(1) .top-feature__list__item__inner {
    transition-delay: 0.15s;
  }
}
@media (min-width: 768px) {
  .page-top .top-feature__list__item:nth-child(2) .top-feature__list__item__inner {
    transition-delay: 0.3s;
  }
}
@media (min-width: 768px) {
  .page-top .top-feature__list__item:nth-child(3) .top-feature__list__item__inner {
    transition-delay: 0.45s;
  }
}
.page-top .top-feature__list__item .selection_icon {
  width: 138rem;
  border-radius: 50%;
  margin: 0 auto 20rem;
  box-shadow: 8rem 8rem 15rem rgba(0, 0, 0, 0.15);
}
@media (min-width: 768px) {
  .page-top .top-feature__list__item .selection_icon {
    width: 190rem;
  }
}
.page-top .top-feature__list__item .selection_title {
  font-size: 20rem;
  letter-spacing: 0.06em;
  font-weight: 600;
}
@media (min-width: 768px) {
  .page-top .top-feature__list__item .selection_title {
    font-size: 24rem;
  }
}
.page-top .top-feature__list__item .selection_title.first {
  color: #F87F7B;
}
.page-top .top-feature__list__item .selection_title.second {
  color: #99D05A;
}
.page-top .top-feature__list__item .selection_title.third {
  color: #F0B633;
}
.page-top .top-feature__list__item .selection_message {
  font-weight: normal;
  text-align: justify;
  font-size: 16rem;
  line-height: 2;
  max-width: 258rem;
  margin: 8rem auto 0;
  letter-spacing: 0;
}
.page-top .top-feature__tel {
  background: var(--colorMain1);
  color: #fff;
  border-radius: 999rem;
}
@media (max-width: 767px) {
  .page-top .top-feature__tel {
    padding: 5rem 0 15rem;
    margin: 50rem auto 0;
    width: 335rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-feature__tel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100rem;
    margin-top: 90rem;
  }
}
.page-top .top-feature__tel__number {
  font-family: var(--fontEn1);
  font-weight: 700;
  font-size: 30rem;
}
@media (min-width: 768px) {
  .page-top .top-feature__tel__number {
    font-weight: 600;
    font-size: 48rem;
  }
}
.page-top .top-feature__tel__number a {
  color: inherit;
}
@media (max-width: 767px) {
  .page-top .top-feature__tel__announce {
    font-size: 14rem;
    line-height: 1.5;
    letter-spacing: 0.06em;
    text-align: left;
    width: fit-content;
    margin: 0 auto;
    padding-left: 20rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-feature__tel__announce {
    font-size: 16rem;
    letter-spacing: 0.06em;
    margin-left: 30rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-feature__button {
    margin-top: 90rem;
  }
}
@media (max-width: 767px) {
  .page-top .top-step__inner {
    padding: 60rem 0 120rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-step__inner {
    padding: 20rem var(--sidePaddingPc1) 140rem;
  }
}
@media (max-width: 767px) {
  .page-top .top-step__inner .top-step__flow {
    padding-top: 80rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-step__inner .top-step__flow {
    position: relative;
    text-align: center;
    width: 100%;
    margin: 0 auto 0;
    max-width: var(--widthDefault);
    padding-top: 110rem;
  }
}
@media (max-width: 767px) {
  .page-top .top-step__inner .flow_inner {
    margin: 0 var(--sidePaddingSp1);
  }
}
@media (min-width: 768px) {
  .page-top .top-step__inner .flow_inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .page-top .top-step__inner .flow_item:not(:last-child) {
    margin-bottom: 70rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-step__inner .flow_item {
    width: 33.3333333333%;
  }
}
.page-top .top-step__inner .flow_number {
  color: var(--colorMain2);
  font-family: var(--fontEn1);
  font-size: 54rem;
  font-weight: 600;
  width: 80rem;
  height: 80rem;
  margin: 0 auto 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (min-width: 768px) {
  .page-top .top-step__inner .flow_number {
    font-size: 56rem;
    width: 120rem;
    height: 120rem;
    margin-bottom: 22rem;
    font-weight: 500;
  }
}
.page-top .top-step__inner .flow_number:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--colorMain2);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.1;
  z-index: -1;
}
.page-top .top-step__inner .flow_number:before {
  content: "";
  display: block;
  width: 146rem;
  height: 146rem;
  border-radius: 50%;
  background: var(--colorMain2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  z-index: -2;
}
@media (min-width: 768px) {
  .page-top .top-step__inner .flow_number:before {
    width: 200rem;
    height: 200rem;
  }
}
.page-top .top-step__inner .flow_title {
  font-weight: 600;
  font-size: 20rem;
  margin-bottom: 5rem;
  letter-spacing: 0.04em;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .page-top .top-step__inner .flow_title {
    font-size: 24rem;
    margin-bottom: 5rem;
  }
}
.page-top .top-step__inner .flow_title::before {
  content: "";
  width: 30rem;
  height: 2rem;
  display: block;
  background: var(--colorMain2);
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -26rem;
  z-index: 2;
}
@media (min-width: 768px) {
  .page-top .top-step__inner .flow_title::before {
    top: -31rem;
  }
}
.page-top .top-step__inner .flow_title::after {
  position: absolute;
  left: 0;
  content: "";
  height: 1rem;
  width: 100%;
  background: #C1BCBC;
  top: -25rem;
}
@media (min-width: 768px) {
  .page-top .top-step__inner .flow_title::after {
    top: -30rem;
  }
}
.page-top .top-step__inner .flow_text {
  text-align: left;
  font-weight: 400;
  font-size: 16rem;
  letter-spacing: 0;
  line-height: 1.8;
}
@media (max-width: 767px) {
  .page-top .top-step__inner .flow_text {
    padding: 0 20rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-step__inner .flow_text {
    max-width: 388rem;
    width: fit-content;
    margin: 0 auto;
    padding: 0 10rem;
    line-height: 2;
    letter-spacing: 0.04em;
  }
}
.page-top .top-step__inner .flow_text li {
  text-indent: -1.1em;
  padding-left: 1.1em;
}
@media (max-width: 767px) {
  .page-top .top-step__inner .flow_text li + li {
    margin-top: 10rem;
  }
}
.page-top .top-step__buttons {
  margin-top: 60rem;
}
@media (min-width: 768px) {
  .page-top .top-step__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70rem;
  }
  .page-top .top-step__buttons .m-basic-button {
    margin: 0 15rem;
  }
}
.page-top .top-trouble__inner {
  background-color: var(--colorBg2);
  text-align: center;
  padding-top: 70rem;
  padding-bottom: 75rem;
}
@media (min-width: 768px) {
  .page-top .top-trouble__inner {
    padding: 105rem var(--sidePaddingPc1) 140rem;
  }
}
.page-top .top-trouble__illust {
  margin-top: 50rem;
}
@media (min-width: 768px) {
  .page-top .top-trouble__illust {
    max-width: 1117rem;
    margin: 20rem auto 0;
  }
}
.page-top .top-trouble__lead {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999rem;
  background: var(--colorMain1);
  color: #fff;
  font-size: 22rem;
  line-height: 1.5;
  font-weight: 600;
  max-width: 335rem;
  height: 100rem;
  margin: 95rem auto 0;
  position: relative;
}
@media (min-width: 768px) {
  .page-top .top-trouble__lead {
    max-width: var(--widthDefault);
    font-size: 32rem;
  }
}
.page-top .top-trouble__lead:before {
  content: "";
  display: block;
  width: 70rem;
  height: 34rem;
  background: url("/assets/img/top/mark1.svg");
  position: absolute;
  top: -40rem;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .page-top .top-trouble__lead:before {
    top: -45rem;
  }
}
.page-top .top-trouble__announce {
  margin-top: 35rem;
}
@media (min-width: 768px) {
  .page-top .top-trouble__announce {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-trouble__announce .access {
    margin-right: 60rem;
  }
}
.page-top .top-trouble__announce .tel {
  color: var(--colorMain1);
  font-family: var(--fontEn1);
  font-size: 32rem;
  font-weight: 700;
  line-height: 1;
}
@media (max-width: 767px) {
  .page-top .top-trouble__announce .tel {
    margin-top: 30rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-trouble__announce .tel {
    font-size: 40rem;
  }
}
.page-top .top-trouble__announce .tel a {
  color: inherit;
}
.page-top .top-trouble__announce .time {
  letter-spacing: 0;
  font-size: 14rem;
  text-align: left;
  width: fit-content;
  margin: 8rem auto 0;
}
@media (min-width: 768px) {
  .page-top .top-trouble__announce .time {
    margin: 4rem auto 0;
  }
}
@media (min-width: 768px) {
  .page-top .top-trouble__button {
    margin: 0;
  }
}
.page-top .top-access__inner {
  text-align: center;
  padding: 90rem 0 120rem;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .page-top .top-access__inner {
    max-width: var(--widthDefault);
    width: calc(100% - var(--sidePaddingPc1) * 2);
    padding: 140rem 0 200rem;
    font-weight: bold;
  }
}
.page-top .top-access__inner .top-access_method {
  margin-top: 70rem;
  margin-bottom: 50rem;
}
@media (min-width: 768px) {
  .page-top .top-access__inner .top-access_method {
    display: flex;
    margin-top: 104rem;
    margin-bottom: 71rem;
    box-sizing: border-box;
    gap: 0 30rem;
  }
}
.page-top .top-access__inner .top-access_method__item {
  position: relative;
}
@media (max-width: 767px) {
  .page-top .top-access__inner .top-access_method__item {
    box-shadow: 5rem 5rem 10rem rgba(0, 0, 0, 0.15);
    padding: 50rem 0 25rem;
    margin: 0 var(--sidePaddingSp1);
  }
  .page-top .top-access__inner .top-access_method__item:not(:last-child) {
    margin-bottom: 50rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-access__inner .top-access_method__item {
    box-shadow: 8rem 8rem 15rem rgba(0, 0, 0, 0.15);
    padding: 50rem 45rem 40rem;
    width: 33.3333333333%;
    height: auto;
    flex-shrink: 1;
  }
}
.page-top .top-access__inner .imgBox {
  width: 57rem;
  position: absolute;
  left: 50%;
  top: -20rem;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .page-top .top-access__inner .imgBox {
    width: 80rem;
    top: -50rem;
  }
}
.page-top .top-access__inner .method_des {
  text-align: left;
  letter-spacing: 0.04em;
  font-weight: 400;
  width: fit-content;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .page-top .top-access__inner .method_des {
    padding: 0 30rem;
  }
}
.page-top .top-access__inner .method_title {
  font-weight: 500;
  font-size: 20rem;
  margin-bottom: 8rem;
}
@media (min-width: 768px) {
  .page-top .top-access__inner .method_title {
    margin-bottom: 6rem;
  }
}
.page-top .top-access__inner .other_des {
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: 0;
}
@media (max-width: 767px) {
  .page-top .top-access__inner .other_des {
    width: 292rem;
    margin: 45rem auto 50rem;
    font-size: 16rem;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .page-top .top-access__inner .other_des {
    text-align: center;
    font-size: 18rem;
    margin-bottom: 66rem;
  }
}
@media (max-width: 767px) {
  .page-top .top-access__inner .company_profile {
    width: 292rem;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .page-top .top-access__inner .company_profile {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
.page-top .top-access__inner .company_profile dl {
  font-size: 16rem;
  letter-spacing: 0em;
}
@media (min-width: 768px) {
  .page-top .top-access__inner .company_profile dl {
    display: flex;
    padding: 3rem 0;
    letter-spacing: 0.06em;
  }
}
.page-top .top-access__inner .company_profile dt {
  background: var(--colorBg2);
  text-align: left;
  font-weight: 500;
}
@media (max-width: 767px) {
  .page-top .top-access__inner .company_profile dt {
    padding: 4rem 0 4rem 16rem;
    margin-bottom: 10rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-access__inner .company_profile dt {
    max-width: 190rem;
    padding: 16rem 0 16rem 33rem;
    width: 100%;
    letter-spacing: 0;
    display: flex;
    align-items: center;
  }
}
.page-top .top-access__inner .company_profile dd {
  width: 100%;
  text-align: left;
  font-weight: 400;
  position: relative;
}
@media (max-width: 767px) {
  .page-top .top-access__inner .company_profile dd {
    padding: 0 0 20rem 16rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-access__inner .company_profile dd {
    margin-left: 30rem;
    padding: 16rem 0;
    letter-spacing: 0;
  }
  .page-top .top-access__inner .company_profile dd:after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: solid 1px #DEDDDD;
    position: absolute;
    bottom: -3rem;
    left: 0;
  }
}
@media (max-width: 767px) {
  .page-top .top-access__inner .company_profile dl:last-child dd {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .page-top .top-access__inner .company_profile dl:last-child dd:after {
    content: none;
  }
}
@media (min-width: 768px) {
  .page-top .top-access__inner .company_profile .textBox {
    width: 60%;
    font-weight: 500;
    margin-right: 30rem;
  }
}
.page-top .top-access__inner .gmap {
  position: relative;
}
@media (max-width: 767px) {
  .page-top .top-access__inner .gmap {
    height: 267rem;
    margin-bottom: 48rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-access__inner .gmap {
    width: 40%;
    height: 0;
    padding-top: 38%;
  }
}
.page-top .top-access__inner .gmap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-top .top-price {
  margin-top: 60rem;
}
@media (min-width: 768px) {
  .page-top .top-price {
    margin-top: calc(-20.5vw + 350rem);
  }
}
.page-top .top-price__inner .section-ad {
  background-size: cover;
  position: relative;
  background: url("/assets/img/top/section_bg_sp.png") no-repeat center top/100% auto;
}
@media (min-width: 768px) {
  .page-top .top-price__inner .section-ad {
    padding: 0 var(--sidePaddingPc1);
    background-image: url("/assets/img/top/section_bg.png");
  }
}
.page-top .top-price__inner .section-ad .message-container {
  padding-top: 430rem;
  height: 1072rem;
}
@media (min-width: 768px) {
  .page-top .top-price__inner .section-ad .message-container {
    max-width: 1184rem;
    height: 104vw;
    margin-right: auto;
    margin-left: auto;
    padding-top: 28vw;
  }
}
.page-top .top-price__inner .section-ad .message {
  text-align: left;
}
@media (max-width: 767px) {
  .page-top .top-price__inner .section-ad .message {
    width: fit-content;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .page-top .top-price__inner .section-ad .message {
    margin-right: 0;
    margin-left: auto;
    width: fit-content;
  }
}
.page-top .top-price__inner .section-ad .message .message_title {
  font-weight: 600;
  color: var(--colorMain1);
  background: linear-gradient(transparent 61%, #FFEB4B 60%);
  display: inline;
  font-size: 28rem;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .page-top .top-price__inner .section-ad .message .message_title {
    font-size: clamp(35.7rem,3.5vw,42rem);
    line-height: clamp(55.25rem,5.4166666667vw,65rem);
  }
}
.page-top .top-price__inner .section-ad .message .price {
  line-height: 1;
  font-size: 75rem;
  margin-top: 13rem;
}
@media (min-width: 768px) {
  .page-top .top-price__inner .section-ad .message .price {
    margin-top: clamp(15.3rem,1.5vw,18rem);
    font-size: clamp(127.5rem,12.5vw,150rem);
  }
}
.page-top .top-price__inner .section-ad .message .price em {
  font-family: var(--fontEn1);
  font-weight: 700;
  display: inline-block;
  position: relative;
  top: 4rem;
}
@media (min-width: 768px) {
  .page-top .top-price__inner .section-ad .message .price em {
    top: clamp(6.8rem,0.6666666667vw,8rem);
  }
}
.page-top .top-price__inner .section-ad .message .price span {
  font-weight: 900;
  display: inline-block;
  font-size: 30rem;
  margin-left: -15rem;
}
@media (min-width: 768px) {
  .page-top .top-price__inner .section-ad .message .price span {
    font-size: clamp(53.55rem,5.25vw,63rem);
    margin-left: clamp(-29.75rem,-2.9166666667vw,-35rem);
  }
}
.page-top .top-price__inner .section-ad .message .price rt {
  font-weight: 500;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .page-top .top-price__inner .section-ad .message .price rt {
    font-size: clamp(25.5rem,2.5vw,30rem);
  }
}
.page-top .top-price__comment {
  font-weight: 600;
  letter-spacing: 0.06em;
}
@media (max-width: 767px) {
  .page-top .top-price__comment {
    font-size: 16rem;
    line-height: 2;
    width: 292rem;
    margin-top: 25rem;
    margin-bottom: 45rem;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .page-top .top-price__comment {
    margin-top: clamp(34rem,3.3333333333vw,40rem);
    margin-bottom: clamp(61.2rem,6vw,72rem);
    font-size: clamp(15.3rem,1.5vw,18rem);
  }
}
@media (min-width: 768px) {
  .page-top .top-price__button {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .page-top .top-price__button:not(:last-child) {
    margin-bottom: 20rem;
  }
}