@keyframes mvFadein {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@keyframes mvFadeinText {
  0% {
    opacity:0;
    transform: translateX(-10rem);
  }
  100% {
    opacity:1;
    transform: translateX(0);
  }
}

.top-mv__main {
  position: relative;
  width: 100%;
  @include sp {
    height: 600rem;
    padding-top: var(--heightHeaderSp);
    background: url("/assets/img/top/main_bg_sp.jpg") #E76436 no-repeat center bottom / 100% auto;
  }
  @include wide {
    background: url("/assets/img/top/main_bg.jpg") #E76436 no-repeat bottom 0% right 28% / 1500rem auto;
    padding:calc(var(--heightHeaderPc) + 150rem) var(--sidePaddingPc1) 0;
    height: 960rem;
  }
}
.top-mv__message-container {
  @include sp {
    margin: 0 auto;
    width: 340rem;
  }
  @include wide {
    max-width: 1070rem;
    margin:0 auto;    
  }
} 
.top-mv__message {
  @include wide {
    max-width: 496rem;
  }
}
.top-mv__message__content {
  display: block;
  font-size: 0;
  margin-left: -25rem;
  @include sp {
  }
}
.top-mv__message__content, 
.top-mv__message__text {
  opacity: 0;
}
.top-mv__message-container.is-active {
  .top-mv__message__content {
    animation:mvFadein 1.3s cubic-bezier(.215,.61,.355,1) forwards;
  }
  .top-mv__message__text {
    animation:mvFadeinText 1.3s cubic-bezier(.215,.61,.355,1) forwards;
  }
}
.top-mv__logo-text {
  @include sp {
    display: none;
  }
  @include wide {
    position: absolute;
    bottom: 40rem;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1388rem;
    width: calc(100% - var(--sidePaddingPc1) * 2);
  }
}
.top-mv__button {
  width: 160rem;
  margin-top: 30rem;
  opacity: 0;
  animation:mvFadein 1.3s 5s cubic-bezier(.215,.61,.355,1) forwards;
  @include wide {
    width: 300rem;
    margin-top: 60rem;
  }
}
.top-mv__message-container.is-active {
  .top-mv__button {
    animation:mvFadein 1.3s 1.5s cubic-bezier(.215,.61,.355,1) forwards;
  }
}