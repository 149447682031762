.top-trouble__inner {
  background-color: var(--colorBg2);
  text-align: center;
  padding-top: 70rem;
  padding-bottom: 75rem;
  @include wide {
    padding: 105rem var(--sidePaddingPc1) 140rem;
  }
}
.top-trouble__illust {
  margin-top: 50rem;
  @include wide {
    max-width: 1117rem;
    margin:20rem auto 0;
  }
}
.top-trouble__lead {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:999rem;
  background: var(--colorMain1);
  color: #fff;
  font-size: 22rem;
  line-height: 1.5;
  font-weight: 600;
  max-width: 335rem;
  height: 100rem;
  margin:95rem auto 0;
  position: relative;
  @include wide {
    max-width: var(--widthDefault);
    font-size: 32rem;
  }
  &:before {
    content: '';
    display: block;
    width: 70rem;
    height: 34rem;
    background: url("/assets/img/top/mark1.svg");
    position: absolute;
    top: -40rem;
    left: 50%;
    transform: translateX(-50%);
    @include wide {
      top: -45rem;
    }
  }
}
.top-trouble__announce {
  margin-top: 35rem;
  @include wide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90rem;
  }
  .access {
    @include wide {
      margin-right: 60rem;
    }
  }
  .tel {
    color: var(--colorMain1);
    font-family: var(--fontEn1);
    font-size: 32rem;
    font-weight: 700;
    line-height: 1;
    @include sp {
      margin-top: 30rem;
    }
    @include wide {
      font-size: 40rem;
    }
    a {
      color:inherit;
    }
  }
  .time {
    letter-spacing: 0;
    font-size: 14rem;
    text-align: left;
    width: fit-content;
    margin:8rem auto 0;
    @include wide {
      margin:4rem auto 0;
    }
  }
}
.top-trouble__button {
  @include wide {
    margin:0;
  }
}
