.top-access__inner {
  text-align: center;
  padding:90rem 0 120rem;
  margin: 0 auto;
  @include wide {
    max-width: var(--widthDefault);
    width: calc(100% - var(--sidePaddingPc1) * 2);
    padding: 140rem 0 200rem;
    font-weight: bold;
  }
  .top-access_method {
    margin-top: 70rem;
    margin-bottom: 50rem;
    @include wide {
      display: flex;
      margin-top: 104rem;
      margin-bottom: 71rem;
      box-sizing: border-box;
      gap:0 30rem; 
    }
  }
  .top-access_method__item {
    position: relative;
    @include sp {
      box-shadow: 5rem 5rem 10rem rgba(0, 0, 0, 0.15);
      padding:50rem 0 25rem;
      margin:0 var(--sidePaddingSp1);
      &:not(:last-child) {
        margin-bottom: 50rem;
      }
    }
    @include wide {
      box-shadow: 8rem 8rem 15rem rgba(0, 0, 0, 0.15);
      padding: 50rem 45rem 40rem;
      width: calc(100% / 3);
      height: auto;
      flex-shrink:1;
    }
  }
  .imgBox {
    width: 57rem;
    position: absolute;
    left: 50%;
    top: -20rem;
    transform: translateX(-50%);
    @include wide {
      width: 80rem;
      top: -50rem;
    }
  }
  .method_des {
    text-align: left;
    letter-spacing: 0.04em;
    font-weight: 400;
    width: fit-content;
    margin:0 auto;
    @include sp {
      padding:0 30rem;
    }
  }
  .method_title{
    font-weight:500;
    font-size: 20rem;
    margin-bottom: 8rem;
    @include wide {
      margin-bottom: 6rem;
    }
  }
  .other_des{
    font-weight: 600;
    line-height: 1.8;
    letter-spacing: 0;
    @include sp {
      width: 292rem;
      margin:45rem auto 50rem;
      font-size: 16rem;
      text-align: left;
    }
    @include wide {
      text-align: center;
      font-size: 18rem;
      margin-bottom: 66rem;
    }
  }
  .company_profile{
    @include sp {
      width: 292rem;
      margin:0 auto;
    }
    @include wide {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
    dl{
      font-size: 16rem;
      letter-spacing: 0em;
      @include wide {
        display: flex;
        padding:3rem 0;
        letter-spacing: 0.06em;
      }
    }
    dt{
      background: var(--colorBg2);
      text-align: left;
      font-weight: 500;
      @include sp {
        padding: 4rem 0 4rem 16rem;
        margin-bottom: 10rem;
      }
      @include wide {
        max-width: 190rem;
        padding: 16rem 0 16rem 33rem;
        width: 100%;
        letter-spacing: 0;
        display: flex;
        align-items: center;
      }
    }
    dd{
      width: 100%;
      text-align: left;
      font-weight: 400;
      position: relative;
      @include sp {
        padding:0 0 20rem 16rem;
      }
      @include wide {
        margin-left: 30rem;
        padding: 16rem 0;
        letter-spacing: 0;
        &:after {
          content: '';
          display: block;
          width: 100%;
          border-bottom: solid 1px #DEDDDD;
          position: absolute;
          bottom: -3rem;
          left: 0;
        }
      }
    }
    dl:last-child {
      dd {
        @include sp {
          padding-bottom: 0;
        }
        &:after {
          @include wide {
            content: none;
          }
        }
      }
    }
  }
  .company_profile .textBox{
    @include wide {
      width: 60%;
      font-weight: 500;
      margin-right: 30rem;
    }
  }
  .gmap {
    position: relative;
    @include sp {
      height: 267rem;
      margin-bottom: 48rem;
    }
    @include wide {
      width: 40%;
      height: 0;
      padding-top: 38%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}