.top-feature {
  display: flex;
  justify-content: center;
  margin-top: 100rem;
  @include wide {
    margin-top: 160rem;
  }
}
.top-feature__inner {
  text-align: center;
  margin:0 var(--sidePaddingSp1);
  @include wide {
    width: calc(100% - var(--sidePaddingPc1) * 2);
    max-width: var(--widthDefault);
    margin:0 auto;
  }
}
.top-feature__heading {
  .m-section-heading__main {
    color:var(--colorMain1);
    font-size: 32rem;
    @include wide {
      font-size: 48rem;
    }
  }
}
.top-feature__sub-heading {
  font-size: 28rem;
  font-weight: 600;
  line-height: 1.785;
  color:var(--colorMain1);
  margin-top: 30rem;
  @include wide {
    font-size: 42rem;
    margin-top: 50rem;
  }
}
.top-feature__sub-heading__item {
  display: block;
  &:not(:last-child) {
    margin-bottom: 15rem;
    @include wide {
      margin-bottom: 20rem;
    }
  }
}
.top-feature__sub-heading__line {
  background:linear-gradient(transparent 60%, var(--colorMain3) 0%);
}
.top-feature__lead {
  font-weight: 700;
  font-size: 28rem;
  margin-top: 28rem;
  letter-spacing: 0.08em;
  @include wide {
    font-size: 48rem;
  }
}
.top-feature__list {
  margin-top: 50rem;
  @include wide {
    display: flex;
    justify-content: space-between;
    margin-top: 120rem;
  }
}
.top-feature__list__item {
  @include sp {
    width: 292rem;
    margin:0 auto;
  }
  @include wide {
    width: 100%;
  }
  &:not(:last-child) {
    @include sp {
      border-bottom: 2px dotted #C1BCBC;
      padding-bottom: 34rem;
      margin-bottom: 48rem;
    }
    @include wide {
      border-right: 2px dotted #C1BCBC;
    }
  }
  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      .top-feature__list__item__inner {
        @include wide {
          transition-delay:$i*.15s;
        }
      }
    }
  }
  .selection_icon {
    width: 138rem;
    border-radius: 50%;
    margin: 0 auto 20rem;
    box-shadow:8rem 8rem 15rem rgba(0, 0, 0, 0.15);
    @include wide {
      width: 190rem;
    }
  }
  .selection_title {
    font-size: 20rem;
    letter-spacing: 0.06em;
    font-weight: 600;
    @include wide {
      font-size: 24rem;
    }
    &.first {
      color: #F87F7B;
    }
    &.second {
      color: #99D05A;
    }
    &.third {
      color: #F0B633
    }
  }
  .selection_message {
    font-weight: normal;
    text-align: justify;
    font-size: 16rem;
    line-height: 2;
    max-width: 258rem;
    margin:8rem auto 0;
    letter-spacing: 0;
  }
}
.top-feature__tel {
  background: var(--colorMain1);
  color: #fff;
  border-radius: 999rem;
  @include sp {
    padding:5rem 0 15rem;
    margin: 50rem auto 0;
    width: 335rem;
  }
  @include wide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100rem;
    margin-top: 90rem;
  }
}
.top-feature__tel__number {
  font-family: var(--fontEn1);
  font-weight: 700;
  font-size: 30rem;
  @include wide {
    font-weight: 600;
    font-size: 48rem;
  }
  a {
    color: inherit;
  }
}
.top-feature__tel__announce {
  @include sp {
    font-size: 14rem;
    line-height: 1.5;
    letter-spacing: 0.06em;
    text-align: left;
    width: fit-content;
    margin:0 auto;
    padding-left: 20rem;
  }
  @include wide {
    font-size: 16rem;
    letter-spacing: 0.06em;
    margin-left: 30rem;
  }
}
.top-feature__button {
  @include wide {
    margin-top: 90rem;
  }
}