.top-step__inner {
  @include sp {
    padding:60rem 0 120rem;
  }
  @include wide {
    padding:20rem var(--sidePaddingPc1) 140rem;
  }
  .top-step__flow {
    @include sp {
      padding-top: 80rem;
    }
    @include wide {
      position: relative;
      text-align: center;
      width: 100%;
      margin:0 auto 0;
      max-width: var(--widthDefault);
      padding-top: 110rem;
    }
  }
  .flow_inner {
    @include sp {
      margin:0 var(--sidePaddingSp1);
    }
    @include wide {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  .flow_item {
    @include sp {
      &:not(:last-child) {
        margin-bottom: 70rem;
      }
    }
    @include wide {
      width: calc(100% / 3);
    }
  }
  .flow_number {
    color:var(--colorMain2);
    font-family: var(--fontEn1);
    font-size: 54rem;
    font-weight: 600;
    width: 80rem;
    height: 80rem;
    margin:0 auto 35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include wide {
      font-size: 56rem;
      width: 120rem;
      height: 120rem;
      margin-bottom: 22rem;
      font-weight: 500;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background:var(--colorMain2);
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.1;
      z-index: -1;
    }
    &:before {
      content: '';
      display: block;
      width: 146rem;
      height: 146rem;
      border-radius: 50%;
      background:var(--colorMain2);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      opacity: 0.1;
      z-index: -2;
      @include wide {
        width: 200rem;
        height: 200rem;
      }
    }
  }
  .flow_title {
    font-weight: 600;
    font-size: 20rem;
    margin-bottom: 5rem;
    letter-spacing: 0.04em;
    position: relative;
    text-align: center;
    @include wide {
      font-size: 24rem;
      margin-bottom: 5rem;
    }
    &::before {
      content: '';
      width: 30rem;
      height: 2rem;
      display: block;
      background:var(--colorMain2);
      position: absolute;
      margin:0 auto;
      left: 0;
      right: 0;
      top: -26rem;
      z-index: 2;
      @include wide {
        top: -31rem;
      }
    }
    &::after {
      position: absolute;
      left: 0;
      content: "";
      height: 1rem;
      width: 100%;
      background: #C1BCBC;
      top: -25rem;
      @include wide {
        top: -30rem;
      }
    }
  }
  .flow_text {
    text-align: left;
    font-weight: 400;
    font-size: 16rem;
    letter-spacing: 0;
    @include sp {
      padding:0 20rem;
    }
    line-height: 1.8;
    @include wide {
      max-width: 388rem;
      width: fit-content;
      margin: 0 auto;
      padding:0 10rem;
      line-height: 2;
      letter-spacing: 0.04em;
    }
    li {
      text-indent: -1.1em;
      padding-left: 1.1em;
    }
    li+li {
      @include sp {
        margin-top: 10rem;
      }
    }
  }
}
.top-step__buttons {
  margin-top: 60rem;
  @include wide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:70rem;
    .m-basic-button {
      margin:0 15rem;
    }
  }
}
