.top-price {
  margin-top:60rem;
  @include wide {
    margin-top: calc(-20.5vw + 350rem);
  }
}
.top-price__inner {
  .section-ad {
    background-size: cover;
    position: relative;
    background: url("/assets/img/top/section_bg_sp.png") no-repeat center top / 100% auto;
    @include wide {
      padding:0 var(--sidePaddingPc1);
      background-image: url("/assets/img/top/section_bg.png");
    }
    .message-container {
      padding-top: 430rem;
      height: 1072rem;
      @include wide {
        max-width: 1184rem;
        height: 104vw;
        margin-right: auto;
        margin-left: auto;
        padding-top: 28vw;
      }
    }
    .message {
      text-align: left;
      @include sp {
        width: fit-content;
        margin:0 auto;
      }
      @include wide {
        margin-right: 0;
        margin-left: auto;
        width: fit-content;
      }
      .message_title{
        font-weight: 600;
        color: var(--colorMain1);
        background: linear-gradient(transparent 61%, #FFEB4B 60%);
        display: inline;
        font-size: 28rem;
        line-height: 1.5;
        @include wide {
          font-size: pcLiquidSize(42);
          line-height: pcLiquidSize(65);
        }
      }
      .price {
        line-height: 1;
        font-size: 75rem;
        margin-top: 13rem;
        @include wide {
          margin-top: pcLiquidSize(18);
          font-size: pcLiquidSize(150);
        }
        em {
          font-family: var(--fontEn1);
          font-weight: 700;
          display: inline-block;
          position: relative;
          top: 4rem;
          @include wide {
            top: pcLiquidSize(8);
          }
        }
        span {
          font-weight: 900;
          display: inline-block;
          font-size: 30rem;
          margin-left: -15rem;
          @include wide {
            font-size: pcLiquidSize(63);
            margin-left: pcLiquidSize(-35);
          }
        }
        rt {
          font-weight: 500;
          letter-spacing: 0;
          @include wide {
            font-size: pcLiquidSize(30);
          }
        }
      }
    }
  }
}
.top-price__comment {
  font-weight: 600;
  letter-spacing: 0.06em;
  @include sp {
    font-size: 16rem;
    line-height: 2;
    width: 292rem;
    margin-top: 25rem;
    margin-bottom: 45rem;
    margin-right: auto;
    margin-left: auto;
  }
  @include wide {
    margin-top: pcLiquidSize(40);
    margin-bottom: pcLiquidSize(72);
    font-size: pcLiquidSize(18);
  }
}
.top-price__button {
  @include wide {
    margin-left: 0;
  }
  &:not(:last-child) {
    @include wide {
      margin-bottom: 20rem;
    }
  }
}
